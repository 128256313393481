<template>
  <div class="main">
    <div
      class="dropzone-container"
      :style="isDragging && 'border-color: #2196f3;'"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <label for="fileInput" class="file-label">
        <div v-if="uploadSuccess">
          <h3>Uploaded!</h3>
        </div>
        <div v-if="isDragging">Release to drop files here.</div>
        <div v-else>Drop files here or <u>click here</u> to upload.</div>
      </label>
      <div class="preview-container mt-4" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
            <img class="preview-img" :src="generateURL(file)" />
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <b>×</b>
            </button>
          </div>
        </div>
      </div>
      <v-btn :loading="isUploading" v-if="files && files.length > 0" @click="uploadFiles">Upload</v-btn>
    </div>
  </div>
</template>

<style>

.main {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.dropzone-container {
    padding: 4rem;
    width: 600px;
    /* background: #f7fafc; */
    border-radius: 7px;
    border: 2px dashed;
    border-color: #9e9e9e;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 20px;
    display: block;
    cursor: pointer;
}

.preview-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.preview-card {
    display: flex;
    padding: 10px;
    margin-left: 5px;
}

.preview-img {
    width: auto;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #a2a2a2;
    background-color: #a2a2a2;
}

</style>


<script>
import axios from 'axios';
export default {
  data() {
    return {
      isDragging: false,
      files: [],
      isUploading: false,
      uploadSuccess: false,
    };
  },
  props: {
    uploadPoint: String,
  },
  methods: {
    onChange() {
      const self = this;
      let incomingFiles = Array.from(this.$refs.file.files);
      const fileExist = self.files.some((r) =>
        incomingFiles.some(
          (file) => file.name === r.name && file.size === r.size
        )
      );
      if (fileExist) {
        self.showMessage = true;
        alert("New upload contains files that already exist");
      } else {
        self.files.push(...incomingFiles);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
      this.uploadSuccess = false;
    },
    remove(i) {
        this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
          URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    async uploadFiles() {
      try {
        this.isUploading = true
        const files = this.files;
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("file", file);
        });

        let data = await axios.post(this.uploadPoint,formData, {
          header : {
            'Content-Type' : 'multipart/form-data'
          }
        })
        if(data.error) throw data.error
        this.uploadSuccess = true
        this.files = [];
        this.$emit('cb', true)
      } catch (error) {
        console.log(error)
        this.$emit('cb', false)
      } finally {
        this.isUploading = false
      }
    },
  },
};
</script>